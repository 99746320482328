import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Button,
  notification,
  Switch,
  Space,
  Skeleton,
} from 'antd';

import useBreadcrumb from '../../../core/hooks/useBreadcrumb';
import usePageTitle from '../../../core/hooks/usePageTitle';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { userInfo } from 'os';
import { useEffect, useState } from 'react';
import { Authentication } from '../../../auth/Auth';
import { Lojas } from '../../../sdk/@types/Restaurantes';
import LojaService from '../../../sdk/services/Loja.service';
import { updateUser } from '../../../core/store/Auth.slice';
import { useDispatch } from 'react-redux';

import { UserService, Usuarios } from '../../../sdk';
import ProdutosService from '../../../sdk/services/Produtos.service';
import useAuth from '../../../core/hooks/useAuth';
import { Produtos } from '../../../sdk/@types/Produtos';
import CurrencyInput from '../../components/CurrencyInput';
import useProduto from '../../../core/hooks/useProduto';
import { useForm } from 'antd/lib/form/Form';
import CategoriaService from '../../../sdk/services/Categoria.service';

interface FormularioDeProdutosSimplificadoProps {
  produto?: any;
  produtoId: string;
  restauranteId: string;
  onSuccess?: () => any;
}

export default function FormularioDeProdutosSimplificado(
  props: FormularioDeProdutosSimplificadoProps
) {
  usePageTitle('Lista de Lojas');
  let { user } = useAuth();
  const access_token = localStorage.getItem('accessToken');
  const [reload, setReload] = useState<boolean>(true);
  const [ativo, setAtivo] = useState<boolean>(true);
  const [lojas, setLojas] = useState<Usuarios.comboLojas[]>();
  const [categorias, setCategorias] = useState<any>();
  const [categoriaEscolhida, setCategoriaEscolhida] = useState<any>();
  const [produto, setProduto] = useState<any>();
  const [restauranteSelecionado, setRestauranteSelecionado] =
    useState<Usuarios.comboLojas>();
  const [restauranteAtual, setRestauranteAtual] = useState<Usuarios.ListaRestaurantes>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const onSearchCategoria = (value: string) => {};
  const onChangeCategoria = (categoriaAux: any) => {
    setCategoriaEscolhida(categoriaAux);
    console.log(categoriaAux);
  };
  useEffect(() => {
    if (user?.restaurantes?.id) {
      setLoading(true);
      CategoriaService.getByIdRestaurante(user?.restaurantes?.id as string)
        .then(setCategorias)
        .catch((error) => {
          setLoading(false);
          setError(new Error(error.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user?.restaurantes, reload]);
  const decodedToken: Authentication.AccessTokenDecodedBody = jwtDecode(
    access_token as string
  );

  useEffect(() => {
    setLoading(true);
    if (!produto) {
      ProdutosService.getById(props.produtoId, props.restauranteId)
        .then((produto) => {
          setProduto(produto);
          setCategoriaEscolhida(produto.categoria.id);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.produtoId, props.restauranteId, produto, props.produto.categoria.id]);

  const [error, setError] = useState<Error>();
  const dispatch = useDispatch();
  const history = useHistory();
  const onSearchLoja = (value: string) => {};
  const onChangeLoja = (value: any) => {
    value = JSON.parse(value);

    setRestauranteSelecionado(value as Usuarios.comboLojas);
    UserService.getRestaurantesByID(value.id)
      .then(trataRestauranteAtual)
      .then(setRestauranteAtual);
  };
  let tokenTechsapp = '';
  useEffect(() => {
    if (user?.grupos?._embedded.grupos[0].id == 1) {
      setLojas(trataLojasUsuario(user));
    }
    if (user?.grupos?._embedded.grupos[0].id == 5) {
      UserService.getAllRestaurantes()
        .then(trataLojas)
        .then(setLojas)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp, user]);

  const [form] = useForm();

  function trataRestauranteAtual(
    restaurantes: Usuarios.ListaRestaurantes
  ): Usuarios.ListaRestaurantes {
    if (user && restaurantes) {
      let userAux = {
        grupos: user.grupos,
        usuario: user.usuario,
        restaurantes: restaurantes,
      };
      user = userAux;

      userAux.restaurantes = restaurantes;
      dispatch(updateUser(userAux));
    }
    return restaurantes;
  }
  function trataLojas(
    lojas: Usuarios.ArrayDeRestaurantesDoUsuario
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    lojas._embedded.restaurantes.map((restaurante) => {
      const restauranteAux: Usuarios.comboLojas = {
        id: restaurante.id,
        index: indexCombo,
        nome: restaurante.nome,
        dominio: restaurante.dominio,
        token: restaurante.basic_token,
        cnpj: restaurante.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    });

    return comboRestaurantes;
  }

  function trataLojasUsuario(
    usuario: Usuarios.UsuarioDetalhadoGrupo
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    if (usuario.restaurantes) {
      const restauranteAux: Usuarios.comboLojas = {
        id: usuario.restaurantes.id,
        index: indexCombo,
        nome: usuario.restaurantes.nome,
        dominio: usuario.restaurantes.dominio,
        token: usuario.restaurantes.basic_token,
        cnpj: usuario.restaurantes.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    }

    return comboRestaurantes;
  }

  function trataSwitch() {
    setAtivo(!ativo);
    console.log(ativo);
  }

  return (
    <>
      {user?.grupos?._embedded.grupos[0].id == 5 ? (
        <Col xs={24} lg={13}>
          <Form.Item label={'Loja'} name={'loja'} style={{ width: '70%' }}>
            <Select
              placeholder={'Selecione o loja'}
              showSearch
              onChange={onChangeLoja}
              onSearch={onSearchLoja}
              optionFilterProp='children'
              defaultValue={lojas ? lojas[0].nome : ''}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {lojas?.map((restaurante) => (
                <Select.Option value={JSON.stringify(restaurante)}>
                  {restaurante.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        ''
      )}
      {loading ? (
        <Skeleton></Skeleton>
      ) : (
        <Form
          form={form}
          autoComplete={'off'}
          layout={'horizontal'}
          initialValues={produto}
          onFinish={async (formProduto: any) => {
            setLoading(true);
            console.log(ativo);
            formProduto.ativo = ativo;
            console.log(formProduto);

            try {
              let produtoAtualizado: Produtos.Produto = {
                id: formProduto.id,
                nome: formProduto.nome,
                descricao: formProduto.descricao,
                preco: formProduto.preco,
                custo: formProduto.custo,
                ativo: formProduto.ativo,
                codigo: formProduto.codigo,
                ean: props.produto.ean,
                unidade: props.produto.unidade,
                ntipoItem: props.produto.ntipoItem,
                ncm: props.produto.ncm,
                origem: props.produto.origem,
                cfop: props.produto.cfop,
                cst: props.produto.cst,
                categoria: categoriaEscolhida,
              };

              let produtoResp = await ProdutosService.atualizarProduto(
                produtoAtualizado,
                props.restauranteId,
                produto.id
              );
              setLoading(false);
              if (props.onSuccess) {
                props.onSuccess();
              }

              console.log(produto);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Row align={'middle'}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={'Nome'}
                name={'nome'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `O nome não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input placeholder={'E.x.: xxxxxxxxxxxx'} />
              </Form.Item>

              <Form.Item
                label={'Descrição'}
                name={'descricao'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                  {
                    max: 255,
                    message: `A descrição não pode ter mais de 255 caracteres`,
                  },
                ]}
              >
                <Input type='text' placeholder={'E.x.: xxxxxxxxxxx'} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={'Valor'}
                name={'preco'}
                rules={[
                  {
                    required: true,
                    message: 'O campo é obrigatório',
                  },
                ]}
              >
                <CurrencyInput
                  onChange={(_, value) =>
                    form.setFieldsValue({
                      preco: value,
                    })
                  }
                />
              </Form.Item>

                            <Form.Item
                                  label={'Custo'}
                                  name={'custo'}
                                
                                          >
                            <CurrencyInput
                                          onChange={(_, value) =>
                                             form.setFieldsValue({
                                              custo: value,
                                             })
                                          }
                                  />
                            </Form.Item>
              <Form.Item
                label={'Código de Integração'}
                name={'codigo'}
              >
                <Input
                  style={{
                    width: '95%',
                  }}
                  placeholder={'E.x.: xxxxxxxxxxxxx'}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              lg={24}
              style={{ display: 'flex', justifyContent: 'end', gap: '30px' }}
            >
              {props.produto.categoria ? (
                <Form.Item
                  label={'Categoria'}
                  name={'categoria'}
                  style={{ width: '100%' }}
                  initialValue={props.produto.categoria.id}
                >
                  <Select
                    placeholder={'Selecione a categoria'}
                    showSearch
                    onChange={onChangeCategoria}
                    onSearch={onSearchCategoria}
                    defaultValue={props.produto.categoria.id}
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {categorias?.map((categoria: any) => (
                      <Select.Option value={categoria.id}>{categoria.nome}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
            </Col>
            <Col
              xs={24}
              lg={24}
              style={{ display: 'flex', justifyContent: 'end', gap: '30px' }}
            >
              <Form.Item label='Status' name={'ativo'} valuePropName='checked'>
                <Switch
                  onChange={trataSwitch}
                  defaultChecked
                  checkedChildren={'Ativos'}
                  unCheckedChildren={'Inativos'}
                />
              </Form.Item>
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                  Salvar Produto
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
